import { Box, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/userWidget";
import TimeSheetOverviewWidget from "scenes/widgets/timeSheetOverviewWidget";
import { useState, useEffect } from "react";
import { MoreTime } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import FlexBetween from "components/flexBetween";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  const [timeSheets, setTimeSheets] = useState([]);
  const styleDesktop = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };

  const styleMobile = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: 20,
    position: "fixed",
  };

  const getTimeSheets = async () => {
    const apiRoute =
      user.roles.includes("OWNER") || user.roles.includes("ADMIN")
        ? "timesheet/all"
        : `timesheet/${user._id}/details`;
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URI}/${apiRoute}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    setTimeSheets(data);
  };

  useEffect(() => {
    getTimeSheets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Navbar />
      {(user.roles.includes("OWNER") || user.roles.includes("ADMIN")) && (
        <Box
          width="100%"
          padding="2rem 6%"
          paddingBottom={0}
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? "100%" : undefined}>
            <UserWidget userId={user._id} />
          </Box>
        </Box>
      )}
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box
          flexBasis={isNonMobileScreens ? "100%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          {timeSheets.map((item, idx) => {
            return (
              <Box key={idx} sx={{ paddingBottom: "1rem" }}>
                <TimeSheetOverviewWidget employeeTimeSheets={item} />
              </Box>
            );
          })}
        </Box>
      </Box>
      {user.roles.includes("USER") && (
        <Fab
          style={isNonMobileScreens ? styleDesktop : styleMobile}
          variant="extended"
          color="primary"
        >
          <FlexBetween gap="10px" onClick={() => navigate("/recordTime")}>
            <MoreTime />
            <Typography>Zeit erfassen</Typography>
          </FlexBetween>
        </Fab>
      )}
    </Box>
  );
};

export default HomePage;
