import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  timeSheets: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
    setEmployees: (state, action) => {
      if (state.user) {
        state.user.collegues = action.payload.collegues;
      } else {
        console.error("user employees non existent");
      }
    },
    setTimeSheets: (state, action) => {
      state.timeSheets = action.payload.timeSheets;
    },
    setTimeSheet: (state, action) => {
      const updatedTimeSheets = state.timeSheets.map((timeSheet) => {
        if (timeSheet._id === action.payload.timeSheet._id)
          return action.payload.timeSheet;
        return timeSheet;
      });
      state.timeSheets = updatedTimeSheets;
    },
  },
});

export const {
  setMode,
  setEmployees,
  setLogin,
  setLogout,
  setTimeSheets,
  setTimeSheet,
} = authSlice.actions;

export default authSlice.reducer;
