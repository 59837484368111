import {
  Box,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import TimeSheetOverviewWidget from "scenes/widgets/timeSheetOverviewWidget";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const TimeSheetsPage = () => {
  const { year, month, userId } = useParams();
  const [timeSheets, setTimeSheets] = useState({});
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const getTimeSheets = async () => {
    const canSeeDetails =
      user.roles.includes("ADMIN") ||
      user.roles.includes("OWNER") ||
      user._id === userId;

    if (canSeeDetails) {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URI}/timesheet/${year}/${month}/${userId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      setTimeSheets(data[0]);
    }
  };

  useEffect(() => {
    getTimeSheets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box height={"100%"}>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        paddingBottom={0}
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box
          flexBasis={isNonMobileScreens ? "100%" : undefined}
          height={"100%"}
        >
          <TimeSheetOverviewWidget employeeTimeSheets={timeSheets} />
        </Box>
      </Box>
      <Box
        width="100%"
        height={!isNonMobileScreens ? "calc(100% - 255px - 80px)" : "100%"}
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box
          flexBasis={isNonMobileScreens ? "100%" : undefined}
          height={"100%"}
        >
          <TableContainer component={Paper} sx={{ height: "100%" }}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell size="small">Datum</TableCell>
                  <TableCell size="small" align="left">
                    Beginn
                  </TableCell>
                  <TableCell size="small" align="left">
                    Ende
                  </TableCell>
                  <TableCell size="small" align="right">
                    Pause
                  </TableCell>
                  <TableCell size="small" align="right">
                    Gesamt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeSheets?.documents?.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {dayjs(row.date).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell size="small" align="left">
                      {dayjs(row.timeFrom).format("HH:mm")}
                    </TableCell>
                    <TableCell size="small" align="left">
                      {dayjs(row.timeUntil).format("HH:mm")}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {row.breakDuration}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {row.duration?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeSheetsPage;
