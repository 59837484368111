import { Box, Button, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/de";

const now = dayjs();

const timeSheetSchema = yup.object().shape({
  description: yup.string().default(() => ""),
  note: yup.string().default(() => ""),
  date: yup.date().required(),
  timeFrom: yup.string().required(),
  timeUntil: yup.string().required(),
  breakDuration: yup.number().integer(),
});

const initialValues = {
  description: "Arbeitszeit",
  note: "",
  date: now.format("YYYY-MM-DD"),
  timeFrom: now.format("HH:mm"),
  timeUntil: now.format("HH:mm"),
  breakDuration: 0,
};

const TimeRecordForm = () => {
  const { palette } = useTheme();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const recordTime = async (values, onSubmitProps) => {
    values.userId = user._id;
    values.timeFrom = `${values.date}T${values.timeFrom}:00.00Z`;
    values.timeUntil = `${values.date}T${values.timeUntil}:00.00Z`;
    values.date = `${values.date}T00:00:00.00Z`;

    const recordTimeResponse = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URI}/timesheet/recorTime`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      }
    );
    const response = await recordTimeResponse.json();

    onSubmitProps.resetForm();
    if (response) {
      navigate("/home");
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await recordTime(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={timeSheetSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Datum"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.date}
              name="date"
              type="date"
              error={Boolean(touched.date) && Boolean(errors.date)}
              helperText={touched.date && errors.date}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Uhrzeit von"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.timeFrom}
              name="timeFrom"
              type="time"
              error={Boolean(touched.timeFrom) && Boolean(errors.timeFrom)}
              helperText={touched.timeFrom && errors.timeFrom}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Uhrzeit bis"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.timeUntil}
              name="timeUntil"
              type="time"
              error={Boolean(touched.timeUntil) && Boolean(errors.timeUntil)}
              helperText={touched.timeUntil && errors.timeUntil}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="Bescreibung"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              name="description"
              error={
                Boolean(touched.description) && Boolean(errors.description)
              }
              helperText={touched.description && errors.description}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Notiz"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
              name="note"
              error={Boolean(touched.note) && Boolean(errors.note)}
              helperText={touched.note && errors.note}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Pause dauer (Minuten)"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.breakDuration}
              name="breakDuration"
              error={
                Boolean(touched.breakDuration) && Boolean(errors.breakDuration)
              }
              helperText={touched.breakDuration && errors.breakDuration}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              {"Speichern"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default TimeRecordForm;
