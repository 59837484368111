import { CalendarMonth, AvTimer, QueryStats } from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import FlexBetween from "components/flexBetween";
import WidgetWrapper from "components/widgetWrapper";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const TimeSheetOverviewWidget = ({ employeeTimeSheets }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  return (
    <WidgetWrapper>
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
        onClick={() =>
          navigate(
            `/timesheets/${employeeTimeSheets?.year}/${employeeTimeSheets?.month}/${employeeTimeSheets?.userId}`
          )
        }
      >
        <FlexBetween gap="1rem">
          <Box>
            <Typography
              variant="h6"
              color={dark}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
            >
              {`${employeeTimeSheets?.firstName} ${employeeTimeSheets?.lastName}`}
            </Typography>
          </Box>
        </FlexBetween>
        <QueryStats />
      </FlexBetween>
      <Divider />
      <FlexBetween gap="1rem">
        <Box p="1rem 0">
          <Box display="flex" alignItems="center" gap="1rem">
            <CalendarMonth fontSize="large" sx={{ color: main }} />
            <Typography color={medium}>
              {new Date(
                employeeTimeSheets?.year,
                employeeTimeSheets?.month - 1
              ).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "long",
              })}
            </Typography>
          </Box>
          <Box p="1rem 0" display="flex" alignItems="center" gap="1rem">
            <AvTimer fontSize="large" sx={{ color: main }} />
            <Typography color={medium}>Geleistete Stunden</Typography>
          </Box>
        </Box>

        <Box p="1rem 1rem">
          <Box display="flex" alignItems="center" gap="1rem">
            <Typography variant="h1" fontWeight="500" color="green">
              {employeeTimeSheets?.totalDuration?.toFixed(2)}h
            </Typography>
          </Box>
        </Box>
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default TimeSheetOverviewWidget;
